<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent>
        <!-- <el-button class="right" style="width: 88px; height: 36px; margin-top: -46px;" @click="$router.go(-1)">返回</el-button> -->
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <el-select
              v-model.trim="formInline.areaId"
              @change="areaChange"
              filterable
              size="15"
              placeholder="请选择"
          >
            <el-option label="全部" value></el-option>
            <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.region')" prop="streetId">
          <el-select v-model.trim="formInline.streetId" filterable size="15" placeholder="请选择">
            <el-option label="全部" value></el-option>
            <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
            ></el-option>
          </el-select>
        </el-form-item> -->
              <el-form-item :label="$t('searchModule.park_name')">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Vehicle_type')" label-width="80px">
                <el-select filterable v-model.trim="formInline.plateNumberType">
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="不含无牌车" value="1"></el-option>
                  <el-option label="无牌车" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.charge_type')" prop="scopeId">
                <el-select v-model.trim="formInline.scopeId" filterable size="15">
                  <el-option label="全部" value></el-option>
                  <el-option label="一类区" value="1"></el-option>
                  <el-option label="二类区" value="2"></el-option>
                  <el-option label="三类区" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Appearance_time')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.date')" prop="dateType" style="width: 200px">
          <el-select v-model.trim="formInline.dateType" filterable size="15" style="width: 100px">
            <el-option label="日" value="day">日</el-option>
            <el-option label="月" value="month">月</el-option>
          </el-select>
        </el-form-item> -->
              <!-- <timeRangePick
          @timeChange="timeChange"
          ref="timeRangePicker"
          :defalutDate="defaultTime"
          :type="formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
          :valueFormat="formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
        /> -->
              <!-- <el-date-picker
            v-model="startTime"
            :picker-options="pickerOptions"
            type="date"
            placeholder="选择日期"
            v-show="formInline.dateType == 'day'"
            style="margin-right:10px;"
        ></el-date-picker>
        <span v-show="formInline.dateType == 'day'">至</span>
        <el-date-picker
            v-model="endTime"
            :picker-options="pickerOptions"
            type="date"
            placeholder="选择日期"
            v-show="formInline.dateType == 'day'"
            style="margin-right:10px;"
        ></el-date-picker>
        <el-date-picker
            v-model="startTime"
            :picker-options="pickerOptions"
            type="month"
            placeholder="选择月"
            v-show="formInline.dateType == 'month'"
            style="margin-right:10px;"
        ></el-date-picker> -->
              <!-- <span v-show="formInline.dateType == 'month'">至</span>
        <el-date-picker
            v-model="endTime"
            :picker-options="pickerOptions"
            type="month"
            placeholder="选择月"
            v-show="formInline.dateType == 'month'"
            style="margin-right:10px;"
        ></el-date-picker> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData()"
                :loading="loading"
                >查询
              </el-button>
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="clearData('formInline')"
                :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportExcelReport()"
                :loading="loading"
                v-if="$route.meta.authority.button.export"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <div class="content">
        <CircularChartFold
          class="graphShadow paddingT10 paddingL20 marginTB20"
          :arrearageHistorytableData="tableData"
          :type="type1"
          :lastCountTime="lastCountTime"
        ></CircularChartFold>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingT10 paddingB10">
        <el-table v-loading="loading" :data="tableData_" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, dateFormat, formatNum, formatWeekDate } from "@/common/js/public.js";
import CircularChartFold from "./circularChart6";
import myComponent from "@/components/autocomplete/myautoComponent";
// import timeRangePick from "@/components/timePicker";

export default {
  name: "arrearageHistory",
  data() {
    const newDate = new Date();
    let date = this.dateFormat(newDate, "yyyy-MM");
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      tableData_: [],
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > endTime;
      //   }
      // },
      // startTime: startTime,
      // endTime: endTime,
      selectkeys: ["date", "month", "week", "year"],
      defaultTime: [startTime, endTime],
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      businessTypes: [],
      loading: false,
      tableData: [],
      type1: "c1",
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 计费类型
        scopeId: "",
        dateType: "day",
        startTime: "",
        endTime: "",
      },
      tableCols: [
        {
          prop: "_dataDate",
          label: this.$t("list.date"),
          width: "auto",
          // formatter: (row) => {
          //   if (row.dataDate) {
          //     // 周粒度要单独处理
          //     if (this.$refs.datePicker.getDateType() == 3) {
          //       return formatWeekDate(row.dataDate);
          //     } else {
          //       return row.dataDate;
          //     }
          //   }
          // },
        },
        {
          prop: "shouldPay",
          label: "应收金额(元)",
          width: "",
          formatter: (row) => {
            if (row.shouldPay) {
              return formatNum(Number(row.shouldPay / 100));
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "arrearageAmount",
          label: "欠费金额(元)",
          width: "",
          formatter: (row) => {
            if (row.arrearageAmount) {
              return formatNum(Number(row.arrearageAmount / 100));
            } else {
              return "0.00";
            }
          },
        },
      ],

      lastCountTime: "",
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    clearData(formname) {
      this.$refs[formname].resetFields();
      // this.$refs.parkInput.modelvalue = "";
      // this.$refs.timeRangePicker.resetTime();
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
      this.formInline.plateNumberType = "";
      // this.getTimeNow();
    },
    // getTimeNow() {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.dateType == 'day') {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.startTime = dateFormat(startTime, 'yyyy-MM-dd');
    //   this.endTime = dateFormat(endTime, 'yyyy-MM-dd');
    // },
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    dateType() {
      let dateStr = "";
      if (this.formInline.month) {
        dateStr = this.dateFormat(this.formInline.month, "yyyy-MM");
      } else {
        dateStr = "";
      }
      return dateStr;
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 格式化日期
    dateFormat(date, fmt) {
      if (!(date instanceof Date)) {
        return "";
      }
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 查询
    searchData() {
      this.loading = true;
      // let flag = this.showLog();
      // if (!flag) return;
      // let dateNum = 1;
      // if (this.formInline.dateType == "day") {
      //   dateNum = 1;
      // } else {
      //   dateNum = 2;
      // }
      this.$axios
        .get("/acb/2.0/debtStatistics/arrearagehistory", {
          data: {
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            scopeId: this.formInline.scopeId,
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            plateNumberType: this.formInline.plateNumberType,
          },
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.lastCountTime = res.value.time;
          // 周粒度需要单独处理

          this.tableData.forEach((item) => {
            if (this.$refs.datePicker.getDateType() == 3) {
              if (item.dataDate) {
                item._dataDate = formatWeekDate(item.dataDate);
              } else {
                item._dataDate = item.dataDate;
              }
            } else {
              item._dataDate = item.dataDate;
            }
          });
          this.tableData_ = [...this.tableData].reverse();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 导出
    exportExcelReport() {
      let opt = {
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        scopeId: this.formInline.scopeId,
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        plateNumberType: this.formInline.plateNumberType,
      };
      exportExcelNew("/acb/2.0/debtStatistics/arrearagehistoryExport", opt, "post");
    },
  },
  created() {
    this.getArea();
    // this.getTimeNow();
  },
  mounted() {
    this.searchData();
  },
  components: {
    CircularChartFold,
    // timeRangePick,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
